/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/**
 * Smaller and better than normalize.css
 * See: https://github.com/sindresorhus/modern-normalize)
 */

import 'modern-normalize/modern-normalize.css';

/**
 * Some general styles goes here
 */

import './src/styles/main.scss';
