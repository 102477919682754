// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-android-tsx": () => import("./../../../src/pages/android.tsx" /* webpackChunkName: "component---src-pages-android-tsx" */),
  "component---src-pages-dashboard-account-settings-tsx": () => import("./../../../src/pages/dashboard/account-settings.tsx" /* webpackChunkName: "component---src-pages-dashboard-account-settings-tsx" */),
  "component---src-pages-dashboard-overview-tsx": () => import("./../../../src/pages/dashboard/overview.tsx" /* webpackChunkName: "component---src-pages-dashboard-overview-tsx" */),
  "component---src-pages-dashboard-user-detail-tsx": () => import("./../../../src/pages/dashboard/user-detail.tsx" /* webpackChunkName: "component---src-pages-dashboard-user-detail-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-guidelines-tsx": () => import("./../../../src/pages/guidelines.tsx" /* webpackChunkName: "component---src-pages-guidelines-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-influencer-tsx": () => import("./../../../src/pages/influencer.tsx" /* webpackChunkName: "component---src-pages-influencer-tsx" */),
  "component---src-pages-iphone-tsx": () => import("./../../../src/pages/iphone.tsx" /* webpackChunkName: "component---src-pages-iphone-tsx" */),
  "component---src-pages-landings-creators-tsx": () => import("./../../../src/pages/landings/creators.tsx" /* webpackChunkName: "component---src-pages-landings-creators-tsx" */),
  "component---src-pages-landings-family-tsx": () => import("./../../../src/pages/landings/family.tsx" /* webpackChunkName: "component---src-pages-landings-family-tsx" */),
  "component---src-pages-landings-travel-tsx": () => import("./../../../src/pages/landings/travel.tsx" /* webpackChunkName: "component---src-pages-landings-travel-tsx" */),
  "component---src-pages-legal-cookies-tsx": () => import("./../../../src/pages/legal/cookies.tsx" /* webpackChunkName: "component---src-pages-legal-cookies-tsx" */),
  "component---src-pages-legal-customizing-tsx": () => import("./../../../src/pages/legal/customizing.tsx" /* webpackChunkName: "component---src-pages-legal-customizing-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-legal-verification-tsx": () => import("./../../../src/pages/legal/verification.tsx" /* webpackChunkName: "component---src-pages-legal-verification-tsx" */),
  "component---src-pages-password-recovery-tsx": () => import("./../../../src/pages/password-recovery.tsx" /* webpackChunkName: "component---src-pages-password-recovery-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-share-tsx": () => import("./../../../src/pages/share.tsx" /* webpackChunkName: "component---src-pages-share-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */)
}

